require('../scss/error.scss');
require('bootstrap');

document.addEventListener('DOMContentLoaded', () => {
    const loginLink = document.querySelectorAll('.js-login-menu');

    if (loginLink.length > 0) {
        Array.from(loginLink).forEach(link => link.remove());
    }
});
